// Importing all base styles form the ArchitectUI Admin Dashboard
@import "architectui/base";

* {
  transition: opacity 500ms;
}

.text-white {
  a {
    color: #fff!important;
  }
}

* {
  -webkit-print-color-adjust: exact;
}

.modal-body button {
  display: none;
  &.show-in-modal {
    display: inline-block!important;
  }
}

// Wartelisten Notizenfeld - speichern per AJAX
textarea {
  &.saved {
    background: rgba(lightgreen, 0.2);
  }
  &.saving {
    background: rgba(lightyellow, 0.2);
  }
}

// All custom styles for the Administration view
.select2-container {
  width: 100% !important;
}

table.table-accordeon tr {
  cursor: pointer;
}

.hiddenRow {
  padding: 0 4px !important;
  background-color: #eeeeee;
  font-size: 13px;
}

.bg-light-yellow {
  background-color: #FFFF99;
}


@media print{
  .card {
    box-shadow: none;
  }
  .app-theme-white.app-container {
    background: none!important;
  }

  .fixed-header .app-main {
    padding-top: 0;
  }

  table {
    margin-bottom: 0!important;
  }

  body{
    font-size:9px!important;
    background: none!important;
  }
  p {
    font-size: 11px;
  }
  tr td, tr th {
    font-size: 11px;
  }
}

@include media-breakpoint-up(xs) {

}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {

}
